import classNames from 'classnames/bind';
import React from 'react';

import { Padding } from '@components/Padding/Padding';
import type { PaddingSize } from '@components/Padding/usePaddingContext';

import styles from './SimpleCard.module.scss';

interface IBaseProps {
  size?: PaddingSize;
  className?: string;
  testId?: string;
  width?: 'auto' | 'full' | number;
  height?: 'auto' | 'full' | number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}

type IPrimaryThemeProps = IBaseProps & {
  theme: 'primary';
};

type ISecondaryThemeProps = IBaseProps & {
  theme: 'secondary';
};

type IProps = IPrimaryThemeProps | ISecondaryThemeProps;

const cx = classNames.bind(styles);

export const SimpleCard: React.FC<IProps> = ({
  size,
  width = 'auto',
  height = 'auto',
  theme,
  className,
  testId,
  onClick,
  children,
}) => (
  <Padding
    className={cx('card', theme, className)}
    data-testid={testId}
    size={size}
    style={{
      width: width === 'full' ? '100%' : width,
      height: height === 'full' ? '100%' : height,
    }}
    onClick={onClick}
  >
    {children}
  </Padding>
);
